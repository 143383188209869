import React from "react"
import { ThemeProvider, Box } from "@chakra-ui/core"
import customTheme from "../utils/theme"
import Scroll from "./locomotiveScroll"
import Header from "./header"
import Footer from "./footer"

import "../assets/style.css"
import "../../node_modules/locomotive-scroll/dist/locomotive-scroll.min.css"

const Layout = ({ children, location }) => (
  <ThemeProvider theme={customTheme}>
    <Scroll callbacks={location} />
    <Header />
    <Box grid>{children}</Box>
    <Box w="90%" maxW={1720} mx="auto" py={[3,3,4,4,5]}>
      <Footer />
    </Box>
  </ThemeProvider>
)

export default Layout
