import React from "react"
import { Flex, Box, Heading, Link, Text, Icon } from "@chakra-ui/core"
import { StaticQuery, graphql } from "gatsby"
import customTheme from "../utils/theme"
import styled from 'styled-components'

const StyledText = styled(Text)`
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
`

export default () => (
  <div data-scroll-section>
    <StaticQuery
      query={siteOptions}
      render={(data) => {
        return (
          <Flex flexWrap="wrap" fontSize={[ customTheme.fontSizes["xl"],customTheme.fontSizes["2xl"],customTheme.fontSizes["2xl"],customTheme.fontSizes["xl"],customTheme.fontSizes["2xl"],customTheme.fontSizes["3xl"] ]} mx={["0","0",-2,-2,-3]}>
            <Box w={["100%","100%","100%","25%"]} px={["0","0",2,2,3]} mb={[2,2,3,"0px"]}>
              <Heading as="h3" mb="0px" fontSize={[ customTheme.fontSizes["xl"],customTheme.fontSizes["2xl"],customTheme.fontSizes["2xl"],customTheme.fontSizes["xl"],customTheme.fontSizes["2xl"],customTheme.fontSizes["3xl"] ]} fontWeight="500" color={customTheme.colors.blue}>Connect</Heading>
              <Link href={`tel:${data.wp.site.acfOptions.phone}`} color={customTheme.colors.gray} _hover={{ color: customTheme.colors.blue}} fontWeight="500">P&emsp;{data.wp.site.acfOptions.phone}</Link>
            </Box>
            <Box w={["100%","100%","100%","37.5%"]} px={["0","0",2,2,3]} mb={[2,2,3,"0px"]}>
              <Text color={customTheme.colors.gray}>
                {data.wp.allSettings.generalSettingsTitle}<br />
                {data.wp.site.acfOptions.address}<br />
                {data.wp.site.acfOptions.city}, {data.wp.site.acfOptions.state} {data.wp.site.acfOptions.zip}<br />
                {data.wp.site.acfOptions.country}
              </Text>
            </Box>
            <Box w={["100%","100%","100%","37.5%"]} px={["0","0",2,2,3]}>
              <Flex justifyContent={["flex-start","flex-start","flex-start","flex-end"]} mx={-1}>
                {data.wpMenu.menuItems.nodes.map((value, index) => {
                  return (
                    <Link href={value.url} key={index} color={customTheme.colors.gray} _hover={{color: customTheme.colors.blue}} border={`1px solid currentColor`} w={["56px","56px","56px","56px","64px","80px"]} h={["56px","56px","56px","56px","64px","80px"]} borderRadius="100%" textAlign="center" pt={["14px","14px","9px","12px","12px","13px"]} mx={1} isExternal>
                      <StyledText>{value.label}</StyledText>
                      <Icon name={value.label} fill="currentColor" size={["24px","24px","24px","24px","32px"]} />
                    </Link>
                  )
                })}
              </Flex>
            </Box>
          </Flex>
        )
      }}
    />
  </div>
)

const siteOptions = graphql`
  query siteOptions {
    wp {
      ...ContactInfo
    }
    wpMenu(slug: { eq: "social-menu" }) {
      ...MenuItems
    }
  }
`