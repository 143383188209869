import React from "react"
import { Heading, Box } from "@chakra-ui/core"
import { Link, StaticQuery, graphql } from "gatsby"
import Logo from "./logo"
import styled from 'styled-components'
import Menu from "./menu"

const StyledHeading = styled(Heading)`
  position: fixed;
  bottom: 0px;
  right: 5%;
  z-index: 999;
`

const Hamburger = styled.button`
  color: white;
  outline: none;
  padding: 0;

  > span {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 30px;

    > span {
      display: block;
      margin-top: -2px;
      position: absolute;
      width: 40px;
      height: 4px;
      background-color: #fff;
      top: 2px;
      transition: background-color 0s linear .13s;

      &:before,
      &:after {
        display: block;
        content: "";
        position: absolute;
        width: 40px;
        height: 4px;
        transition-timing-function: ease;
        transition-duration: .15s;
        transition-property: transform;
        background-color: #fff;
      }
      &:before {
        top: 12px;
        transition: top .1s cubic-bezier(.33333,.66667,.66667,1) .2s,transform .13s cubic-bezier(.55,.055,.675,.19);
      }
      &:after {
        top: 24px;
        transition: top .2s cubic-bezier(.33333,.66667,.66667,1) .2s,transform .13s cubic-bezier(.55,.055,.675,.19);
      }
    }
  }

  &.nav-open {
    > span {
      > span {
        transition-delay: .22s;
        background-color: transparent;

        &:before {
          top: 0;
          transition: top .1s cubic-bezier(.33333,0,.66667,.33333) .15s,transform .13s cubic-bezier(.215,.61,.355,1) .22s;
          transform: translate3d(0,10px,0) rotate(45deg);
        }
        &:after {
          top: 0;
          transition: top .2s cubic-bezier(.33333,0,.66667,.33333),transform .13s cubic-bezier(.215,.61,.355,1) .22s;
          transform: translate3d(0,10px,0) rotate(-45deg);
        }
      }
    }
  }
`


const LogoLink = styled(Link)`
  color: transparent;
  display: inline-block;
  font-size: 0;
  position: relative;
`


const settingsQuery = graphql`
  query SettingsQuery {
    allWp {
      nodes {
        allSettings {
          generalSettingsTitle
          generalSettingsDescription
        }
      }
    }
  }
`

class Header extends React.Component {

  constructor() {
    super();
    this.state = {
      menuOpen: false
    }
    this.handleMenuOpen = this.handleMenuOpen.bind(this)
  }

  handleMenuOpen() {
    this.setState({ menuOpen: !this.state.menuOpen })
  }

  render() {
    return (
      <React.Fragment>
        <StyledHeading id="logo" as="h1">
          <LogoLink to="/">
            {this.props.data.allWp.nodes[0].allSettings.generalSettingsTitle}
            <Logo />
          </LogoLink>
        </StyledHeading>
        <Box pos="absolute" top={[2,2,2,3]} right="5%" zIndex="999">
          <Hamburger onClick={this.handleMenuOpen} className={`${this.state.menuOpen ? 'nav-open' : ''}`}><span><span></span></span></Hamburger>
        </Box>
        <Menu handleMenuOpen={this.handleMenuOpen} menuOpen={this.state.menuOpen} />
      </React.Fragment>
    )
  }
}

export default() => (
  <StaticQuery
    query={settingsQuery} render={data => <Header data={data} />}
  />
)