import { useEffect } from "react"
import { gsap } from 'gsap'
import { CSSPlugin } from 'gsap/CSSPlugin'

// We are excluding this from loading at build time in gatsby-node.js
import LocomotiveScroll from "locomotive-scroll"

import { scroll } from "../utils/theme"

// Force CSSPlugin to not get dropped during build
gsap.registerPlugin(CSSPlugin)

const Scroll = callbacks => {
  useEffect(() => {
    let locomotiveScroll
    locomotiveScroll = new LocomotiveScroll({
      el: document.querySelector(scroll.container),
      ...scroll.options,
    })
    locomotiveScroll.update()

    // const imageBlock1 = document.getElementById("image-block-1")
    // const imageBlock1Top = imageBlock1.getBoundingClientRect().top - imageBlock1.offsetTop
    // console.log(`ib1`, imageBlock1.getBoundingClientRect().top)

    // Exposing to the global scope for ease of use.
    window.scroll = locomotiveScroll

    locomotiveScroll.on("scroll", func => {
      // Update `data-direction` with scroll direction.
      document.documentElement.setAttribute("data-direction", func.direction)
      //console.log(func)

      const speed = Math.abs(func.speed)

      if( func.direction === "down" ) {
        gsap.to("#logo-stretch", 0, { opacity: speed > 0.04 ? 1 : 0 })
        gsap.to("#logo", 0.7, { y: speed > 0.2 ? -speed * 50 : 0 })

        if( func.speed > 0.1 ) {
          //console.log("stretch")

          //logo.style.transform = "translate3d( 0," + (-speed * 100) + "px, 0)"
          //logoStretch.style.opacity = 1
        } else {
          //logo.style.transform = "translate3d( 0,0,0 )"
          //
          //gsap.to("#logo", 0.3, { y: 0 })
          // gsap.to("#logo svg:last-child", 0, { delay: 2, opacity: 0 })
        }
      } else {
        // logo.style.transform = "translate3d( 0,0,0 )"
        // logoStretch.style.opacity = 0
        gsap.to("#logo", 0.7, { y: 0 })
        gsap.to("#logo-stretch", 0, { delay: 0.9, opacity: 0})
      }

      // if( func.scroll.y > imageBlock1Top ) {
      //   console.log('NONONON')
      // }

    })

    return () => {
      if (locomotiveScroll) locomotiveScroll.destroy()
    }
  }, [callbacks])

  return null
}

export default Scroll