import React from "react"
import { Flex, Box, Heading, Link, Text, Icon } from "@chakra-ui/core"
import { StaticQuery, graphql } from "gatsby"
import customTheme from "../utils/theme"
import styled from 'styled-components'

const StyledText = styled(Text)`
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
`

const MenuWrap = styled(Box)`
  transform: translateX(100%);
  transition: transform 1s cubic-bezier(0.87, 0, 0.13, 1);

  &.nav-open {
    transform: translateX(0);
  }
`

const MenuInfo = styled(Box)`
  transform: translateY(110%);
  transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);

  .nav-open & {
    transform: translateY(0);
    transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1) 0.8s;
  }
`

export default ({ menuOpen }) => {

  /*
  const { wpMenu } = useStaticQuery(graphql`
    {
      wpMenu(slug: { eq: "main-menu" }) {
        name
        menuItems {
          nodes {
            label
            url
            parentId
            connectedNode {
              node {
                ... on WpContentNode {
                  uri
                }
              }
            }
          }
        }
      }
    }
  `)*/
  return(
    <MenuWrap
      className={`${menuOpen ? 'nav-open' : ''}`}
      bg={customTheme.colors.blue}
      w={["90%", "80%", "70%", "60%"]}
      h="100%"
      maxW="748px"
      pos="fixed"
      top="0px"
      right="0px"
      zIndex="998"
      boxShadow="-10px 0 30px rgba(0,0,0,0.3)"
    >
      <StaticQuery
        query={menu}
        render={(data) => {
          return (
            <Box
              pt={7}
              px={[3,3,3,4]}
              fontSize={[ customTheme.fontSizes["xl"],customTheme.fontSizes["2xl"],customTheme.fontSizes["2xl"],customTheme.fontSizes["xl"],customTheme.fontSizes["2xl"],customTheme.fontSizes["3xl"] ]}
              pos="relative"
              overflow="hidden"
            >
              <MenuInfo>
                <Heading as="h3" mb={1} fontWeight="500" fontSize={[ customTheme.fontSizes["xl"],customTheme.fontSizes["2xl"],customTheme.fontSizes["2xl"],customTheme.fontSizes["xl"],customTheme.fontSizes["2xl"],customTheme.fontSizes["3xl"] ]} color={customTheme.colors.white}>Connect</Heading>
                <Link href={`mailto:${data.wp.site.acfOptions.email}`} color={customTheme.colors.gold} _hover={{ color: customTheme.colors.white}} fontWeight="500">E&emsp;{data.wp.site.acfOptions.email}</Link><br />
                <Link href={`tel:${data.wp.site.acfOptions.phone}`} color={customTheme.colors.gold} _hover={{ color: customTheme.colors.white}} fontWeight="500">P&emsp;{data.wp.site.acfOptions.phone}</Link>
                <Flex justifyContent="flex-start" mx={-1} mt={3}>
                    {data.wpMenu.menuItems.nodes.map((value, index) => {
                      return (
                        <Link href={value.url} key={index} color={customTheme.colors.gold} _hover={{color: customTheme.colors.white}} border={`1px solid currentColor`} w={["56px","56px","56px","56px","64px","80px"]} h={["56px","56px","56px","56px","64px","80px"]} borderRadius="100%" textAlign="center" pt={["14px","14px","9px","12px","12px","13px"]} mx={1} isExternal>
                          <StyledText>{value.label}</StyledText>
                          <Icon name={value.label} fill="currentColor" size={["24px","24px","24px","24px","32px"]} />
                        </Link>
                      )
                    })}
                    <Link ml="auto" href={`https://www.google.com/maps/place/${data.wp.site.acfOptions.address} ${data.wp.site.acfOptions.city}, ${data.wp.site.acfOptions.state} ${data.wp.site.acfOptions.zip} ${data.wp.site.acfOptions.country}`} color={customTheme.colors.gold} _hover={{color: customTheme.colors.white}} isExternal>
                      <Icon name="Location" fill="currentColor" size={["50px","50px","50px","50px","80px"]} />
                    </Link>
                  </Flex>
                </MenuInfo>
            </Box>
          )
        }}
      />
    </MenuWrap>
  )
}


const menu = graphql`
  query menu {
    wp {
      ...ContactInfo
    }
    wpMenu(slug: { eq: "social-menu" }) {
      ...MenuItems
    }
  }
`