import React from "react"
import { Box } from "@chakra-ui/core"
import customTheme from "../utils/theme"
import styled from 'styled-components'

const LogoBox = styled(Box)`
  svg {
    fill: ${customTheme.colors.white};
    width: 180px;
    height: 44px;

    @media (min-width: ${customTheme.breakpoints[1]}) {
      width: 240px;
      height: 58px;
    }

    @media (min-width: ${customTheme.breakpoints[2]}) {
      width: 340px;
      height: 82px;
    }

    @media (min-width: ${customTheme.breakpoints[4]}) {
      width: 420px;
      height: 102px;
    }
  }
`

const StretchBox = styled(Box)`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;

  svg {
    fill: ${customTheme.colors.white};
    width: 180px;
    height: 1024px;

    @media (min-width: ${customTheme.breakpoints[1]}) {
      width: 240px;
      height: 1365px;
    }

    @media (min-width: ${customTheme.breakpoints[2]}) {
      width: 340px;
      height: 1934px;
    }

    @media (min-width: ${customTheme.breakpoints[4]}) {
      width: 420px;
      height: 2392px;
    }
  }
`

export default () => {

  return (
    <React.Fragment>
      <LogoBox>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 429 103.7">
          <path d="M0 0h19.9v103.7H0zM41.2 61.1H44c9.3 0 17-6.9 17-15.6V25.6H41.2v35.5zM44 69.6h-2.8v34.1h19.9V81c0-8.7-7.8-11.4-17.1-11.4zM81 25.6h21.3v78.1H81zM122.2 25.6v78.1h21.3V45.5c0-10.6-10.1-19.9-21.3-19.9zM163.4 45.5v39.8c0 10.5 8.6 18.5 19.9 18.5V25.6c-11 0-19.9 8.9-19.9 19.9zM204.6 25.6v78.1c11.3 0 19.9-7.9 19.9-18.5V45.5c-.1-11-9-19.9-19.9-19.9zM244.3 85.2c0 10.5 10 18.5 21.3 18.5V25.6h-21.3v59.6zM295.5 25.6c-5.5 0-9.9 4.5-9.9 9.9v68.2h21.3V35.5c-.1-5.2-5.8-9.9-11.4-9.9zM326.7 103.7c11.3 0 19.9-7.9 19.9-18.5V25.6h-19.9v78.1zM326.7 103.7zM367.9 25.6h19.9v78.1h-19.9zM407.7 25.6v78.1H429V45.5c0-10.6-10-19.9-21.3-19.9z"/>
        </svg>
      </LogoBox>
      <StretchBox id="logo-stretch">
        <svg xmlns="http://www.w3.org/2000/svg" width="430" height="2449" viewBox="0 0 430 2449">
          <g data-name="Group 332" fill="#fff"><path data-name="Rectangle 180" d="M0 0h20.476v2449H0z"/><path data-name="Rectangle 181" d="M40.952 27.48h20.476v20.037a17.815 17.815 0 01-17.815 17.815h-2.661V27.48z"/><path data-name="Rectangle 182" d="M40.952 74.038h7.094A13.382 13.382 0 0161.428 87.42V2449H40.952V74.038z"/><path data-name="Rectangle 183" d="M81.904 27.48h20.476V2449H81.904z"/><path data-name="Rectangle 184" d="M122.857 27.48a20.476 20.476 0 0120.476 20.476V2449h-20.476V27.48z"/><path data-name="Rectangle 185" d="M184.285 27.48V2449a20.476 20.476 0 01-20.476-20.477V47.956a20.476 20.476 0 0120.476-20.476z"/><path data-name="Rectangle 186" d="M204.762 27.48a20.476 20.476 0 0120.476 20.476v2380.567A20.476 20.476 0 01204.762 2449V27.48z"/><path data-name="Rectangle 187" d="M245.714 27.48h20.476V2449a20.476 20.476 0 01-20.476-20.477V27.48z"/><path data-name="Rectangle 188" d="M296.904 27.48a10.238 10.238 0 0110.238 10.238V2449h-20.476V37.718a10.238 10.238 0 0110.238-10.238z"/><path data-name="Rectangle 189" d="M327.619 27.48h20.476v2401.043A20.476 20.476 0 01327.619 2449V27.48z"/><path data-name="Rectangle 190" d="M368.571 27.48h20.476V2449h-20.476z"/><path data-name="Rectangle 191" d="M409.523 27.48a20.476 20.476 0 0120.476 20.476V2449h-20.476V27.48z"/></g>
        </svg>
      </StretchBox>
    </React.Fragment>
  )
}
