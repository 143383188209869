import customIcons from "./icons"

const customTheme = {
  breakpoints: ["480px", "768px", "1024px", "1440px", "1720px"],
  colors: {
    white: '#FFFFFF',
    lightgray: '#E5E4E4',
    gray: '#D0D0D0',
    blue: '#2B57FF',
    gold: '#C1B599',
    green: '#ADBDA3'
  },
  fonts: {
    heading: '"Montserrat", sans-serif',
    body: '"Montserrat", sans-serif',
    mono: "Menlo, monospace",
  },
  fontSizes: {
    xs: "0.75rem", // 12
    sm: "0.875rem", // 14
    md: "1rem", // 16
    lg: "1.125rem", // 18
    xl: "1.25rem", // 20
    "2xl": "1.5rem", // 24
    "3xl": "2.25rem", // 36
    "4xl": "2.625rem", // 42
    "5xl": "3rem", //48
    "6xl": "4.5rem", //72,
    "7xl": "5.25rem", //84
    "8xl": "6.25rem", //100
  },
  space: [
    ".25rem",
    ".5rem",
    "1rem",
    "2rem",
    "3rem",
    "4rem",
    "6rem",
    "10rem"
  ],
  icons: {
    ...customIcons,
  }
};

export default customTheme


export const scroll = {
  // Locomotive Scroll
  // https://github.com/locomotivemtl/locomotive-scroll#instance-options
  container: "#___gatsby",
  options: {
    smooth: true,
    smoothMobile: false,
    getDirection: true,
    getSpeed: true,
    touchMultiplier: 2.5,
    lerp: 0.1,
  },
}